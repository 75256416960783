import * as React from "react";
import {FC, useState} from "react";
import * as yup from 'yup';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Col, Form, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './ProductRequestForm.module.scss';
// @ts-ignore
import * as mainStyle from '../../theme/main.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInboxOut} from "@fortawesome/pro-light-svg-icons";
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";

interface FormInputs {
    Name: string,
    Email: string,
    Message: string,
    Phone: string,
    Address: string,
}

const schema = yup.object().shape({
    Name: yup.string().required('Bitte gib deinen Namen an'),
    Email: yup.string().required('Bitte gib eine Email an'),
    Message: yup.string().required('Bitte gib eine Nachricht an'),
    Address: yup.string().required('Bitte gib deine Adresse an'),
    Phone: yup.string().optional(),
})

type Props = {
    vendorID: string
}

const ProductRequestForm: FC<any> = ({vendorID, productName}) => {
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const [sentForm, setSentForm] = useState(false);

    function onSubmit(data: FormInputs) {
        Client.Raw.post('Inquiries/sendVendorRequestMail', {
            Name: data.Name,
            Phone: data.Phone,
            Email: data.Email,
            Message: data.Message,
            Product: productName,
            Address: data.Address ? data.Address : null,
            VendorID: vendorID
        }).then(() => {
            toast.success('Nachricht gesendet!');
            setSentForm(true);
        }).catch(handleApiError);
    }

    return (
        <>
            {!sentForm ?
                <div>
                    <span>Dieses Produkt ist leider derzeit nicht auf Lager.</span><br/>
                    <span className={"mt-2"}>Du kannst dem Händler eine Anfrage schicken, damit er sich bei dir meldet, sobald es wieder lagernd ist.</span>
                    <Form onSubmit={handleSubmit(onSubmit)} className={style.form}>
                        <Row>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formFirstName" className="mb-2">
                                    <Form.Label className={style.inputLabel}> Dein
                                        Name*</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('Name')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Name?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formFirstName" className="mb-2">
                                    <Form.Label className={style.inputLabel}>
                                        E-Mail-Adresse*</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('Email')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Email?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formFirstName" className="mb-2">
                                    <Form.Label className={style.inputLabel}> Telefonnummer</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('Phone')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Phone?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formFirstName" className="mb-2">
                                    <Form.Label className={style.inputLabel}> Adresse*</Form.Label>
                                    <Form.Control type="text" placeholder={"Industriestraße 10, 5600 St. Johann i. P."}
                                                  className={style.inputField} {...register('Address')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Address?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={6} xs={6}>
                                <Form.Group controlId="formFirstName" className="mb-2">
                                    <Form.Label className={style.inputLabel}> Deine Nachricht*</Form.Label>
                                    <Form.Control as="textarea" rows={4} className={style.textArea}
                                                  {...register('Message')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Message?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Button type={"submit"}
                                        className={style.submitButtonBasic}>
                                    <FontAwesomeIcon icon={faInboxOut} className={style.icon}/>
                                    Anfrage senden
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div> : <h3>Vielen Dank für deine Anfrage!</h3>
            }
        </>
    )
}

export default ProductRequestForm;
