import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Badge, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
// @ts-ignore
import * as style from './TopSeller.module.scss';
import {faHeart, faTimes} from "@fortawesome/pro-light-svg-icons";
import {faHeart as hoverHeart} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Client, handleApiError} from "../../services/ApiService";
import {Link} from "gatsby";
import {connect, ConnectedProps} from "react-redux";
import {toast} from "react-toastify";
import Loading from "../Loading/Loading";
import {StaticImage} from "gatsby-plugin-image";
import {setBookmarkAmount} from "../../stores/bookmark/bookmarkSlice";

const mapState = ({member, auth, bookmark}) => ({
    member,
    auth,
    bookmark
})

const mapDispatch = {
    setBookmarkAmount
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type TopSellerProps = PropsFromRedux & {
    title: string,
    type: string,
    background?: boolean,
    categoryID?: string
}

const TopSeller: FC<TopSellerProps> = ({title, type, background, member, auth, setBookmarkAmount, categoryID}) => {
    const [allProducts, setAllProducts] = useState([]);
    const [allBookmarks, setAllBookmarks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let params = '?filter[Hidden]=0&filter[Draft]=0'

        if (categoryID) {
            params += '&filter[VendorID]=' + categoryID;
        }

        switch (type) {
            case 'random':
                params += '&sort=RAND()';
                break;
            case 'newest':
                params += '&sort=CREATED DESC';
                break;
            case 'promoted':
                params += '&filter[Promoted]=1&sort=RAND()';
                break;
            default:
                params += '&sort=RAND()';
                break;
        }

        Client.Raw.get('Product/getActiveProductsMinimalized' + params + '&limit=9').then((res) => {
            setAllProducts(res.data);
            setLoading(false);
        }).catch(handleApiError)

        if (member.MemberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)
            }).catch(handleApiError);
        }
    }, [])

    useEffect(() => {
        if (member.MemberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)
            }).catch(handleApiError);
        }
    }, [member])

    function addBookmark(id: number, product) {
        Client.Bookmark.add(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt zur Merkliste hinzugefügt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count);


                let basketDataLayer = {
                    'cicData': {
                        'cic_product_title': product.Title,
                        'cic_product_category': product.CategoryTitle,
                        'cic_vendor_title': product.VendorTitle,
                        'cic_product_price': product.Price
                    },
                    'event': 'fs.productFavorited'
                };

                console.log(basketDataLayer)

                //@ts-ignore
                if (window.dataLayer) {

                    let gme_add_to_wishlist = {
                        'event': 'add_to_wishlist',
                        'data': {
                            'currency': 'EUR',
                            'value': product.Price,
                            'items': [{
                                'item_id': product.SKU,
                                'item_name': product.Title,
                                'item_category': product.CategoryTitle,
                                'item_category2': product.CategoryTitle2,
                                'item_category3': product.CategoryTitle3,
                                'discount': product.Sale ? product.Price - product.RefPrice : 0,
                            }]
                        }
                    }

                    // @ts-ignore
                    window.dataLayer.push(gme_add_to_wishlist);

                    //@ts-ignore
                    window.dataLayer.push(basketDataLayer);
                }
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function removeBookmark(id: number) {
        Client.Bookmark.remove(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt von der Merkliste entfernt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    return (
        <>
            {loading ? <Loading type={"grow"} message={title + " werden geladen"}/> :
                <>
                    {allProducts.length ?
                        <div className="position-relative">
                            <>
                                {background ?
                                    <div className={style.backgroundColor}>
                                    </div>
                                    : null
                                }
                            </>
                            <div className={style.container}>
                                <>
                                    <p className={style.heading}> {title} </p>
                                    <Row>
                                        {allProducts.map((item: any, index: number) =>
                                            <Col className={style.holder} lg={4} xs={6} key={index}>

                                                <div
                                                    className={style.link}>
                                                    <div className={style.iconHolder}>
                                                        {item.Sale ? <span
                                                            className={"badge position-absolute translate-middle " + style.saleBadge}>Sale</span> : null}
                                                        <Link to={`/${item.CategoryURLTitle}/${item.URLTitle}`}>
                                                            {item.TeaserImage ?
                                                                <img src={item.TeaserImage} className={style.icon}
                                                                     loading={"lazy"}/> :
                                                                <StaticImage
                                                                    src={'../../images/login-overlay-2x.png'}
                                                                    alt={'background'}
                                                                    placeholder="blurred"
                                                                    className={style.icon + ' img-fluid'}/>
                                                            }
                                                        </Link>
                                                        {auth.Key ?
                                                            <>
                                                                <OverlayTrigger
                                                                    key={"top-topseller"}
                                                                    placement={"top"}
                                                                    overlay={(props) =>
                                                                        <Tooltip
                                                                            id={`tooltip-top-topseller`} {...props} >
                                                                            {allBookmarks ?
                                                                                <>
                                                                                    {(allBookmarks.find((bookmark) => {
                                                                                        return bookmark.ID === item.ID;
                                                                                    })) ?
                                                                                        <span> Von der Merkliste entfernen </span> :
                                                                                        <span> Zur Merkliste hinzufügen </span>} </> : null
                                                                            }

                                                                        </Tooltip>
                                                                    }

                                                                >
                                                                    <>
                                                                        {allBookmarks ?
                                                                            <>
                                                                                {(allBookmarks.find((bookmark) => {
                                                                                    return bookmark.ID === item.ID;
                                                                                })) ?
                                                                                    <span className={style.crossHolder}
                                                                                          onClick={() => removeBookmark(item.ID)}>
                                                                <FontAwesomeIcon icon={faTimes}
                                                                                 className={style.cross}/>
                                                            </span> :
                                                                                    <span className={style.heartHolder}
                                                                                          onClick={() => {
                                                                                              addBookmark(item.ID, item)
                                                                                          }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span>
                                                                                }
                                                                            </> :
                                                                            <>
                                                                                {member.MemberID ?

                                                                                    <span className={style.heartHolder}
                                                                                          onClick={() => {
                                                                                              addBookmark(item.ID, item)
                                                                                          }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span> : null
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                </OverlayTrigger>

                                                            </> : null

                                                        }
                                                    </div>

                                                </div>

                                                <p className={style.category}>
                                                    {item.CategoryTitle}
                                                </p>
                                                <p className={style.name}>
                                                    <Link to={`/${item.CategoryURLTitle}/${item.URLTitle}`}>
                                                        {item.Title}
                                                    </Link>
                                                </p>
                                                <span>
                                                {item.Sale ? <span
                                                        className={style.refPrice}>{item.RefNicePrice}</span>
                                                    : null}
                                                    <span className={style.price}>
                                                    {item.NicePrice}
                                                </span>
                                                </span>
                                                <p className={style.mwst}>
                                                    inkl. MwSt.
                                                </p>

                                            </Col>
                                        )}
                                    </Row>
                                </>

                            </div>
                        </div> : null
                    }
                </>

            }

        </>
    )
}

export default connector(TopSeller);
