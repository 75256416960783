// extracted by mini-css-extract-plugin
export var dropDown = "ProductRequestForm-module--dropDown--pWFzb";
export var dropDownChevron = "ProductRequestForm-module--dropDownChevron--4lz76";
export var form = "ProductRequestForm-module--form--rccX+";
export var icon = "ProductRequestForm-module--icon--LZLhA";
export var inputField = "ProductRequestForm-module--inputField--LFmEA";
export var inputFieldBasic = "ProductRequestForm-module--inputFieldBasic--lCC1y";
export var inputLabel = "ProductRequestForm-module--inputLabel--cF9Ws";
export var inputLabelBasic = "ProductRequestForm-module--inputLabelBasic--Len9f";
export var submitButton = "ProductRequestForm-module--submitButton--wCMUr";
export var submitButtonBasic = "ProductRequestForm-module--submitButtonBasic--ky1NE";
export var textArea = "ProductRequestForm-module--textArea--16R8p";