import {FC, useEffect} from "react";
import {Col, Dropdown, Row} from "react-bootstrap";
import {navigate} from "gatsby";
import * as React from "react";
// @ts-ignore
import {amount, isVariant, basketButton, category, company, companyDesc, companyImage, companyImageHolder, dropdownImage, customContainer, dropDown, imageHolder, infoHeading, mainImage, moreBtn, mwst, name, price, prodDesc, prodImage, infoContainer, vendorLogo, selectOptionWrapper, bookmarkIcon, bookmarkButton, modalButton, refPrice, saleBadge} from './ProductPage.module.scss';
import CategoryPage from "../CategoryPage/CategoryPage";

const ProductPageVariantDropdown: FC<any> = ({product, variants}) => {


    return (
        <Row className="align-items-center pt-3">
            <Col lg={3}>
                <p className={amount}>Variante</p>
            </Col>
            <Col lg={9}>
                <Dropdown className={dropDown + ' w-100'}>
                    <Dropdown.Toggle
                        className={selectOptionWrapper + ' w-100 text-start ' + isVariant}
                        variant="success"
                        id="dropdown-basic">
                        {product.Title}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'w-100 mt-1'}>
                        {variants.map((item: any, index: number) => {
                            return (!item.Draft && !item.Hidden) ?
                                <Dropdown.Item className={selectOptionWrapper}
                                               onClick={() => navigate('/' + item.CategoryURLTitle + '/' + item.URLTitle)
                                               }>{(item.ProductGroup.VariantType === 'image') ? <img src={item.PreviewImage} className={dropdownImage} alt={'empty'}/> : null}{item.Title}</Dropdown.Item> : <></>;
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    )
}

export default ProductPageVariantDropdown;
