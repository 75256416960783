// extracted by mini-css-extract-plugin
export var amount = "ProductPage-module--amount--wmVhw";
export var backToOverview = "ProductPage-module--backToOverview--Pu38B";
export var basketButton = "ProductPage-module--basketButton--7bRtg";
export var bookmarkButton = "ProductPage-module--bookmarkButton--cXKVD";
export var bookmarkRemoveButton = "ProductPage-module--bookmarkRemoveButton--rLiTR";
export var category = "ProductPage-module--category--Pk4TV";
export var company = "ProductPage-module--company--84J+f";
export var companyDesc = "ProductPage-module--companyDesc--VJeBu";
export var companyImage = "ProductPage-module--companyImage--GV0+y";
export var companyImageHolder = "ProductPage-module--companyImageHolder--t6msn";
export var customContainer = "ProductPage-module--customContainer--2zfUt";
export var dropDown = "ProductPage-module--dropDown--gOWeC";
export var dropdownImage = "ProductPage-module--dropdownImage--zSIx9";
export var fairPacktLogo = "ProductPage-module--fairPacktLogo--Gb4XL";
export var fairPacktLogoMobile = "ProductPage-module--fairPacktLogoMobile--54OSO";
export var icon = "ProductPage-module--icon--3madK";
export var imageHolder = "ProductPage-module--imageHolder--O9CmE";
export var imageHoverWrapper = "ProductPage-module--imageHoverWrapper--FGc+Z";
export var infoContainer = "ProductPage-module--infoContainer--LUoNe";
export var infoHeading = "ProductPage-module--infoHeading--E5e3R";
export var infoIcon = "ProductPage-module--infoIcon--MF7rH";
export var isVariant = "ProductPage-module--isVariant--T6Th2";
export var mainImage = "ProductPage-module--mainImage--S6q2H";
export var modalButton = "ProductPage-module--modalButton--ihy7g";
export var moreBtn = "ProductPage-module--moreBtn--cFb7t";
export var mwst = "ProductPage-module--mwst--9Pa0X";
export var name = "ProductPage-module--name---4O26";
export var price = "ProductPage-module--price--wPL-F";
export var prodDesc = "ProductPage-module--prodDesc--a50NA";
export var prodImage = "ProductPage-module--prodImage--CxoEY";
export var refPrice = "ProductPage-module--refPrice--+Vcsg";
export var saleBadge = "ProductPage-module--saleBadge--e5iCT";
export var selectOptionWrapper = "ProductPage-module--selectOptionWrapper--QVeWP";
export var slickSlider = "ProductPage-module--slickSlider--q+U+U";
export var sliderImage = "ProductPage-module--sliderImage--9AxnD";
export var variantImageSlider = "ProductPage-module--variantImageSlider--7zd40";
export var variantSliderCaption = "ProductPage-module--variantSliderCaption--rVqpt";
export var vendorLogo = "ProductPage-module--vendorLogo--pi-lV";