// extracted by mini-css-extract-plugin
export var backgroundColor = "TopSeller-module--backgroundColor--kJsFj";
export var category = "TopSeller-module--category--tLYd5";
export var container = "TopSeller-module--container--rvqvZ";
export var cross = "TopSeller-module--cross--sowEz";
export var crossHolder = "TopSeller-module--crossHolder--Z3IZ+";
export var heading = "TopSeller-module--heading--oi4zr";
export var heart = "TopSeller-module--heart--6es-B";
export var heartHolder = "TopSeller-module--heartHolder--qCybd";
export var holder = "TopSeller-module--holder--UAYzl";
export var hoverHeartClass = "TopSeller-module--hoverHeartClass--EpdCN";
export var icon = "TopSeller-module--icon--3CIEo";
export var iconHolder = "TopSeller-module--iconHolder--Ln8EY";
export var link = "TopSeller-module--link--ZH0ba";
export var mwst = "TopSeller-module--mwst--iOhFE";
export var name = "TopSeller-module--name--BJQPB";
export var price = "TopSeller-module--price--v1UJk";
export var refPrice = "TopSeller-module--refPrice--Q2wZ5";
export var saleBadge = "TopSeller-module--saleBadge--800m0";